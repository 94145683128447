import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import TopicCard from '../components/TopicCard';
import { tytSubjects } from '../data/tytSubjects';
import {Link} from "react-router-dom";

const TYTSubjects = () => {
    const [openSubjectId, setOpenSubjectId] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>(''); // Filtreleme için arama terimi

    // Accordion'u açmak veya kapatmak için fonksiyon
    const toggleSubject = (subjectId: string) => {
        setOpenSubjectId(openSubjectId === subjectId ? null : subjectId);
        setSearchTerm(''); // Yeni bir ders açıldığında arama kutusunu sıfırlıyoruz
    };

    // Arama kutusunda yapılan değişiklikleri izlemek
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return (
        <div className="p-8 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 min-h-screen">
            <h2 className="text-4xl font-extrabold text-primary mb-8">TYT Konuları</h2>
            {tytSubjects.map((subject) => (
                <div key={subject.id} className="mb-6">
                    {/* Ders Başlığı */}
                    <button
                        onClick={() => toggleSubject(subject.id)}
                        className="w-full flex justify-between items-center text-left p-4 bg-gray-700 text-white font-semibold rounded-lg shadow-md hover:bg-gray-600 focus:outline-none focus:ring focus:ring-primary transition-colors"
                    >
                        {subject.name}
                        {/* Accordion Icon */}
                        {openSubjectId === subject.id ? (
                            <FaChevronUp className="text-primary" />
                        ) : (
                            <FaChevronDown className="text-primary" />
                        )}
                    </button>

                    {/* Konuları Accordion'da aç/kapat */}
                    {openSubjectId === subject.id && (
                        <div className="mt-4">
                            {/* Arama Kutusu */}
                            <input
                                type="text"
                                placeholder="Konular arasında ara..."
                                className="mb-4 p-2 w-full border rounded-md"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />

                            {/* Filtrelenmiş Konular */}
                            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
                                {subject.topics
                                    .filter((topic) => topic.name.toLowerCase().includes(searchTerm)) // Konuları arama terimine göre filtrele
                                    .map((topic) => (
                                            <TopicCard
                                                key={topic.id}
                                                title={topic.name}
                                                subjectId={subject.id}
                                                topicId={topic.id}
                                                videos={topic.videos}
                                                questions={topic.questions}
                                            />
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TYTSubjects;
