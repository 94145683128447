interface IVideo {
    id: string;
    title: string;
    url: string;
}

interface IQuestion {
    id: string;
    title: string;
    url: string;
}

interface ITopic {
    id: string;
    name: string;
    videos: IVideo[];
    questions: IQuestion[];
}

interface ISubject {
    id: string;
    name: string;
    topics: ITopic[];
}

export const tytSubjects: ISubject[] = [
    {
        id: '1',
        name: 'Matematik',
        topics: [
            {
                id: '1',
                name: 'Temel Kavramlar',
                videos: [{
                    id: '1',
                    title: 'ŞENOL HOCA - TEMEL KAVRAMLAR 1',
                    url: 'https://www.youtube.com/watch?v=e6NAuVYQRsw',
                },
                {
                    id: '2',
                    title: 'ŞENOL HOCA - TEMEL KAVRAMLAR 2',
                    url: 'https://www.youtube.com/watch?v=AU1wr2MasDI',

                },{
                        id: '3',
                        title: 'ŞENOL HOCA - TEMEL KAVRAMLAR 3',
                        url: 'https://www.youtube.com/watch?v=ZODcxowVfS4',
                },
                    {
                        id:'4',
                        title: 'MERT HOCA - TEMEL KAVRAMLAR VİDEO LİSTESİ',
                        url:'https://www.youtube.com/watch?v=wypnfOSNfFE&list=PLrpOv8fS3bzZqda1LgaLIA0hj6az70USI'
                    }
                ],
                questions: [
                    {
                        id: '1',
                        title: 'Temel Kavramlar | Full Tekrar Matematik Konu Özet + Soru Avcısı + Tıpkı Ösym + PDF',
                        url:'https://www.youtube.com/watch?v=0PnoIscECXM'
                    },
                    {
                        id:'2',
                        title:'TUNÇ KURT MATEMATİK - Sayılar, Temel Kavramlar Soru Çözümü (Kolay-Orta Düzey)',
                        url:'https://www.youtube.com/watch?v=p6P2Mwdl3cg',
                    },
                    {
                        id:'3',
                        title:'MERT HOCA - Bölüm Bitirme Testi-1 2025',
                        url:'https://www.youtube.com/watch?v=M-MBFy3jiqs'
                    },
                    {
                        id:'4',
                        title:'MERT HOCA - Bölüm Bitirme Testi-2 2025',
                        url:'https://www.youtube.com/watch?v=c7AxAqexi20'
                    }
                ],
            },
            {
                id: '2',
                name: 'Sayı Basamakları',
                videos: [
                    {
                        id:'1',
                        title:'Bıyıklı Matematik - Sayı Basamakları',
                        url:'https://www.youtube.com/watch?v=uRP6Mc_9g3I'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Sayı Basamakları 1',
                        url:'https://www.youtube.com/watch?v=DvUDBzs9y8s'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Sayı Basamakları 2',
                        url:'https://www.youtube.com/watch?v=hjyeXOCNyqA'
                    },
                    {
                        id:'3',
                        title:'3D Yayınları -  Sayı Basamakları',
                        url:'https://www.youtube.com/watch?v=1utnhR28n5o'
                    }
                ],
            },
            {
                id: '3',
                name: 'Bölme ve Bölünebilme',
                videos: [
                    {
                        id:'1',
                        title:'3D YAYINLARI - Bölme Bölünebilme',
                        url:'https://www.youtube.com/watch?v=Etm44rpT0pY'
                    },
                    {
                        id:'2',
                        title:'Bıyıklı Matematik - Bölme ve Bölünebilme',
                        url:'https://www.youtube.com/watch?v=3BGFtfi0AyE'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title: '3D Yayınları - Bölme ve Bölünebilme',
                        url:'Bölme Bölünebilme Bire Bir ÖSYM Soruları',
                    },
                    {
                        id:'2',
                        title:'Rehber Matematik - Bölme ve Bölünebilme',
                        url:'https://www.youtube.com/watch?v=Zrsg5TJpbSY'
                    }
                ],
            },
            {
                id: '4',
                name: 'EBOB – EKOK',
                videos: [
                    {
                        id:'1',
                        title: 'Bıyıklı Matematik - EBOB ve EKOK',
                        url:'https://www.youtube.com/watch?v=aral0YYLi_U'
                    },
                    {
                        id:'2',
                        title:'3D Yayınları - EBOB ve EKOK',
                        url:'https://www.youtube.com/watch?v=oaOuW-VNrZM'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'3D Yayınları - EBOB ve EKOK Problemleri',
                        url:'https://www.youtube.com/watch?v=LTeam8NkizE'
                    },
                    {
                       id:'2',
                       title:'3D Yayınları - EBOB EKOK Birebir ÖSYM',
                       url:'https://www.youtube.com/watch?v=sKxhLfp3yS4'
                    }
                ],
            },
            {
                id: '5',
                name: 'Rasyonel Sayılar',
                videos: [
                    {
                        id:'1',
                        title:'3D Yayınları - Rasyonel Sayılar',
                        url:'https://www.youtube.com/watch?v=n6qulC7DFEA'
                    }
                ],
                questions: [
                    {
                        id:'2',
                        title:'MERT HOCA - Rasyonel Sayılar 1',
                        url:'https://www.youtube.com/watch?v=7lC03cLsvlQ'
                    },
                    {
                        id:'3',
                        title:'MERT HOCA - Rasyonel Sayılar 2',
                        url:'https://www.youtube.com/watch?v=jDQ7W0yjEIo'
                    }
                ],
            },
            {
                id: '6',
                name: 'Basit Eşitsizlikler',
                videos: [
                    {
                        id:'1',
                        title:'Mert Hoca - Basit Eşitsizlikler 1',
                        url:'https://www.youtube.com/watch?v=dHYAFxvNNsA'
                    },
                    {
                        id:'2',
                        title:'Mert Hoca - Basit Eşitsizlikler 2',
                        url:'https://www.youtube.com/watch?v=wUdeygyxgXA'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'3D Yayınları - Basit Eşitsizlikler',
                        url:'https://www.youtube.com/watch?v=kzNd8LPInZE'
                    }
                ],
            },
            {
                id: '7',
                name: 'Mutlak Değer',
                videos: [
                    {
                        id:'1',
                        title:'3D Yayınları - Mutlak Değer',
                        url:'https://www.youtube.com/watch?v=28hQk0V0AZA'
                    },
                    {
                        id:'2',
                        title:'Yaşar Hoca - Mutlak Değer',
                        url:'https://www.youtube.com/watch?v=gv_KGtJM7yo'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'3D Yayınları - Mutlak Değer Bire Bir ÖSYM Soruları',
                        url:'https://www.youtube.com/watch?v=G3BtJVh53dg'
                    },
                    {
                        id:'2',
                        title:'Rehber Matematik - Mutlak Değer',
                        url:'https://www.youtube.com/watch?v=yIyc_UdgUyg'
                    }
                ],
            },
            {
                id: '8',
                name: 'Üslü Sayılar',
                videos: [
                    {
                        id:'1',
                        title:'Rehber Matematik - Üslü Sayılar',
                        url:'https://www.youtube.com/watch?v=W15chDWTPiY'
                    },
                    {
                        id:'2',
                        title: 'Rehber Matematik - Üslü Sayılar 2',
                        url:'https://www.youtube.com/watch?v=Zaesu057Wzw'
                    },
                    {
                        id:'3',
                        title:'Yaşar Hoca - Üslü Sayılar',
                        url:'https://www.youtube.com/watch?v=oaIjtasrG2o'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'3D Yayınları - Üslü Sayılar',
                        url:'https://www.youtube.com/watch?v=c-WuWCzLQmE'
                    },
                    {
                        id:'2',
                        title:'Rehber Matematik - Üslü Sayılar',
                        url:'https://www.youtube.com/watch?v=7qCHhH7BJ90'
                    }
                ],
            },
            {
                id: '9',
                name: 'Köklü Sayılar',
                videos: [
                    {
                        id:'1',
                        title: 'Yaşar Hoca - Köklü Sayılar',
                        url:'https://www.youtube.com/watch?v=lZniqINIorQ'
                    },
                    {
                        id:'2',
                        title:'Rehber Matematik - Köklü Sayılar',
                        url:'https://www.youtube.com/watch?v=U5tO-86OZho'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Köklü Sayılar',
                        url:'https://www.youtube.com/watch?v=11dJPJfY9zw'
                    },
                    {
                        id:'2',
                        title:'3D Yayınları - Köklü Sayılar',
                        url:'https://www.youtube.com/watch?v=FtBtWHHGQcw'
                    },
                    {
                        id:'3',
                        title:'3D Yayınları - Köklü Sayılar 2',
                        url:'https://www.youtube.com/watch?v=9GljLBhfqHQ'
                    }
                ],
            },
            {
                id: '10',
                name: 'Çarpanlara Ayırma',
                videos: [
                    {
                        id:'1',
                        title:'3D YAYINLARI - Çarpanlara Ayırma',
                        url:'https://www.youtube.com/watch?v=iFdQmX_ak14'
                    },
                    {
                        id:'2',
                        title: 'MERT HOCA - Çarpanlara Ayırma',
                        url:'https://www.youtube.com/watch?v=lvO7HeZXo3I'
                    },
                    {
                        id:'3',
                        title: 'MERT HOCA - Çarpanlara Ayırma 2',
                        url:'https://www.youtube.com/watch?v=Vje_nRc2Z6A'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Çarpanlara Ayırma Bitirme Testi',
                        url:'https://www.youtube.com/watch?v=JJoUr3JvD9M'
                    }
                ],
            },
            {
                id: '11',
                name: 'Oran Orantı',
                videos: [
                    {
                        id:'1',
                        title:'MERT HOCA - Oran Orantı 1',
                        url:'https://www.youtube.com/watch?v=h3eXcwYR1NM'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Oran Orantı 2',
                        url:'https://www.youtube.com/watch?v=pg-v3frx3I8'
                    },
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Oran Orantı Bitirme Testi',
                        url:'https://www.youtube.com/watch?v=pHpEhd_gG_4'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Oran Orantı Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=ym-3l3JpbTE'
                    }
                ],
            },
            {
                id: '12',
                name: 'Denklem Çözme',
                videos: [
                    {
                        id:'1',
                        title:'MERT HOCA - Denklem Çözme 1',
                        url:'https://www.youtube.com/watch?v=Q-tUjKQL4mY'
                    },
                    {
                        id:'2',
                        title:'3D YAYINLARI - Birinci Dereceden Denklemler',
                        url:'https://www.youtube.com/watch?v=GtsHRuua07c'
                    },
                    {
                        id:'3',
                        title:'BENİM HOCAM  - Birinci Dereceden Denklemler',
                        url:'https://www.youtube.com/watch?v=Jlk-X85MnJ4'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Denklem Çözme Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=0N8NXXthW3k'
                    }
                ],
            },
            {
                id: '13',
                name: 'Problemler',
                videos: [
                    {
                        id:'1',
                        title:'Rehber Matematik - Problemler Video Listesi (18 Video)',
                        url:'https://www.youtube.com/watch?v=rtiH20N1Kzs&list=PLVoSZ0D0CB3pxYcpyVK1N0CBuoEQWNRXP'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Problemler  Video Listesi (27 Video)',
                        url:'https://www.youtube.com/watch?v=K_x0zPf8bWg&list=PLXoLsis-Fjz7ntmaXlCncLgZDp1G0GYYo'
                    }
                ],
                questions: [],
            },
            /*{
                id: '14',
                name: 'Sayı Problemleri',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Kesir Problemleri',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Yaş Problemleri',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Hareket Hız Problemleri',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'İşçi Emek Problemleri',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Yüzde Problemleri',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Kar Zarar Problemleri',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Karışım Problemleri',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'Grafik Problemleri',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'Rutin Olmayan Problemler',
                videos: [],
                questions: [],
            },*/
            {
                id: '24',
                name: 'Kümeler – Kartezyen Çarpım',
                videos: [
                    {
                        id:'1',
                        title: 'Bıyıklı Matematik - Kümeler ve Kartezyen Çarpım',
                        url:'https://www.youtube.com/watch?v=26zegScPBLs'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Kümeler ve Kartezyen Çarpım',
                        url:'https://www.youtube.com/watch?v=mSMEHdS5z7A'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Kümeler ve Kartezyen Çarpım Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=8E484r7KeM8'
                    }
                ],
            },
            {
                id: '25',
                name: 'Mantık',
                videos: [
                    {
                        id:'1',
                        title:'MERT HOCA - Mantık',
                        url:'https://www.youtube.com/watch?v=Vzcg_haZW9Q'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Mantık 2',
                        url:'https://www.youtube.com/watch?v=kGjY5R5E8is'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Mantık Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=gZdTOH0GbAg'
                    }
                ],
            },
            {
                id: '26',
                name: 'Fonksiyonlar',
                videos: [
                    {
                        id:'1',
                        title:'MERT HOCA - Fonksiyonlar Video Listesi',
                        url:'https://www.youtube.com/watch?v=v6c6E8_rDEk&list=PLqmsCFwEuHyauLtXgF25ZSb2SfqwlfDFJ'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'SML HOCA MATEMATİK - Fonksiyonlar',
                        url:'https://www.youtube.com/watch?v=KUYPY4Caq2A'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Fonksiyonlar Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=ELzTc-MVkds'
                    },
                    {
                        id:'3',
                        title:'MERT HOCA - Fonksiyonlar Efişinado Testi 2',
                        url:'https://www.youtube.com/watch?v=bY6RLUSB3eg'
                    }
                ],
            },
            {
                id: '27',
                name: 'Polinomlar',
                videos: [
                    {
                        id:'1',
                        title:'Rehber Matematik - Polinomlar',
                        url:'https://www.youtube.com/watch?v=rUEUuF18cNI'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Polinomlar',
                        url:'https://www.youtube.com/watch?v=tZHMhwuZd-w'
                    },
                    {
                        id:'3',
                        title:'MERT HOCA - Polinomlar 2',
                        url:'https://www.youtube.com/watch?v=sjfpa02aOTE'
                    },
                    {
                        id:'4',
                        title:'MERT HOCA - Polinomlar 3',
                        url: 'https://www.youtube.com/watch?v=7JplAD1M-sE'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Polinomlar Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=ekHiaewyFgs'
                    }
                ],
            },
            {
                id: '28',
                name: '2. Dereceden Denklemler',
                videos: [
                    {
                        id:'1',
                        title:'MERT HOCA - 2. Dereceden Denklemler 1',
                        url:'https://www.youtube.com/watch?v=DzPV2vxTmXM'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - 2. Dereceden Denklemler 2',
                        url:'https://www.youtube.com/watch?v=e0h7Y8wXFhE'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - 2. Dereceden Denklemler Bölüm Bitirme Testi',
                        url:'https://www.youtube.com/watch?v=BoXB9Rt9q50'
                    },
                    {
                        id:'1',
                        title:'MERT HOCA - 2. Dereceden Denklemler Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=2sz321IgOuA'
                    }
                ],
            },
            {
                id: '29',
                name: 'Permütasyon ve Kombinasyon',
                videos: [
                    {
                        id:'1',
                        title:'MERT HOCA - Permütasyon ve Kombinasyon',
                        url:'https://www.youtube.com/watch?v=V_PheqHL-XM'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Permütasyon ve Kombinasyon 2',
                        url:'https://www.youtube.com/watch?v=LE0nfgfmn64'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Permütasyon ve Kombinasyon Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=2Fn6u9ObKhs'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Permütasyon ve Kombinasyon Efişinado Testi 2',
                        url:'https://www.youtube.com/watch?v=rWTZsZryWUg'
                    }
                ],
            },
            {
                id: '30',
                name: 'Olasılık',
                videos: [
                    {
                        id:'1',
                        title:'MERT HOCA - Olasılık',
                        url:'https://www.youtube.com/watch?v=yE80qJo8vSM'
                    },
                    {
                        id:'2',
                        title:'MERT HOCA - Olasılık 2',
                        url:'https://www.youtube.com/watch?v=aSmWeEVTJCU'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Olasılık Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=53QWuCKdswE'
                    }
                ],
            },
            {
                id: '31',
                name: 'Veri – İstatistik',
                videos: [
                    {
                        id:'1',
                        title:'MERT HOCA - Veri İstatistik',
                        url:'https://www.youtube.com/watch?v=PQ6QXvJXgh0'
                    },
                    {
                        id:'2',
                        title:'Bııyıklı Matematik - Veri İstatistik',
                        url:'https://www.youtube.com/watch?v=VEZ71l5sOG0'
                    },
                    {
                        id:'3',
                        title:'Benim Hocam - Veri İstatistik',
                        url:'https://www.youtube.com/watch?v=c7CDJr_joSY'
                    }
                ],
                questions: [
                    {
                        id:'1',
                        title:'MERT HOCA - Veri İstatistik Efişinado Testi',
                        url:'https://www.youtube.com/watch?v=uA1T059H7j8'
                    }
                ],
            },
        ],
    },
    {
        id: '2',
        name: 'Geometri',
        topics: [
            {
                id: '1',
                name: 'Temel Kavramlar',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Doğruda Açılar',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Üçgende Açılar',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Özel Üçgenler',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Dik Üçgen',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'İkizkenar Üçgen',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Eşkenar Üçgen',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Açıortay',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Kenarortay',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Eşlik ve Benzerlik',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Üçgende Alan',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Üçgende Benzerlik',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Açı Kenar Bağıntıları',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Çokgenler',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Özel Dörtgenler',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Dörtgenler',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Deltoid',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Paralelkenar',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Eşkenar Dörtgen',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Dikdörtgen',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Kare',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'Yamuk',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'Çember ve Daire',
                videos: [],
                questions: [],
            },
            {
                id: '24',
                name: 'Çemberde Açı',
                videos: [],
                questions: [],
            },
            {
                id: '25',
                name: 'Çemberde Uzunluk',
                videos: [],
                questions: [],
            },
            {
                id: '26',
                name: 'Dairede Çevre ve Alan',
                videos: [],
                questions: [],
            },
            {
                id: '27',
                name: 'Analitik Geometri',
                videos: [],
                questions: [],
            },
            {
                id: '28',
                name: 'Noktanın Analitiği',
                videos: [],
                questions: [],
            },
            {
                id: '29',
                name: 'Doğrunun Analitiği',
                videos: [],
                questions: [],
            },
            {
                id: '30',
                name: 'Dönüşüm Geometrisi',
                videos: [],
                questions: [],
            },
            {
                id: '31',
                name: 'Katı Cisimler',
                videos: [],
                questions: [],
            },
            {
                id: '32',
                name: 'Prizmalar',
                videos: [],
                questions: [],
            },
            {
                id: '33',
                name: 'Küp',
                videos: [],
                questions: [],
            },
            {
                id: '34',
                name: 'Silindir',
                videos: [],
                questions: [],
            },
            {
                id: '35',
                name: 'Piramit',
                videos: [],
                questions: [],
            },
            {
                id: '36',
                name: 'Koni',
                videos: [],
                questions: [],
            },
            {
                id: '37',
                name: 'Küre',
                videos: [],
                questions: [],
            },
            {
                id: '38',
                name: 'Çemberin Analitiği',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '3',
        name: 'Türkçe',
        topics: [
            {
                id: '1',
                name: 'Sözcükte Anlam',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Söz Yorumu',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Deyim ve Atasözü',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Cümlede Anlam',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Paragraf',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Paragrafta Anlatım Teknikleri',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Paragrafta Düşünceyi Geliştirme Yolları',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Paragrafta Yapı',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Paragrafta Konu-Ana Düşünce',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Paragrafta Yardımcı Düşünce',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Ses Bilgisi',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Yazım Kuralları',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Noktalama İşaretleri',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Sözcükte Yapı/Ekler',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Sözcük Türleri',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'İsimler',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Zamirler',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Sıfatlar',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Zarflar',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Edat – Bağlaç – Ünlem',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Fiiller',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'Fiilde Anlam (Kip-Kişi-Yapı)',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'Ek Fiil',
                videos: [],
                questions: [],
            },
            {
                id: '24',
                name: 'Fiilimsi',
                videos: [],
                questions: [],
            },
            {
                id: '25',
                name: 'Fiilde Çatı',
                videos: [],
                questions: [],
            },
            {
                id: '26',
                name: 'Sözcük Grupları',
                videos: [],
                questions: [],
            },
            {
                id: '27',
                name: 'Cümlenin Ögeleri',
                videos: [],
                questions: [],
            },
            {
                id: '28',
                name: 'Cümle Türleri',
                videos: [],
                questions: [],
            },
            {
                id: '29',
                name: 'Anlatım Bozukluğu',
                videos: [],
                questions: [],
            },
        ],
    },

    {
        id: '4',
        name: 'Fizik',
        topics: [
            {
                id: '1',
                name: 'Fizik Bilimine Giriş',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Madde ve Özellikleri',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Sıvıların Kaldırma Kuvveti',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Basınç',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Isı, Sıcaklık ve Genleşme',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Hareket ve Kuvvet',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Dinamik',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'İş, Güç ve Enerji',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Elektrik',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Manyetizma',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Dalgalar',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Optik',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '5',
        name: 'Kimya',
        topics: [
            {
                id: '1',
                name: 'Kimyasal Türler Arası Etkileşimler',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Atom ve Periyodik Sistem',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Kimya Bilimi',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Maddenin Hâlleri',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Doğa ve Kimya',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Kimyanın Temel Kanunları ve Kimyasal Hesaplamalar',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Karışımlar',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Asitler, Bazlar ve Tuzlar',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Kimya Her Yerde',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '6',
        name: 'Biyoloji',
        topics: [
            {
                id: '1',
                name: 'Canlıların Ortak Özellikleri',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Canlıların Temel Bileşenleri',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Hücre ve Organeller – Madde Geçişleri',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Canlıların Sınıflandırılması',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Hücrede Bölünme – Üreme',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Kalıtım',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Bitki Biyolojisi',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Ekosistem',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '7',
        name: 'Tarih',
        topics: [
            {
                id: '1',
                name: 'Tarih ve Zaman',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'İnsanlığın İlk Dönemleri',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Ortaçağ’da Dünya',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'İlk ve Orta Çağlarda Türk Dünyası',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'İslam Medeniyetinin Doğuşu',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'İlk Türk İslam Devletleri',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Yerleşme ve Devletleşme Sürecinde Selçuklu Türkiyesi',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Beylikten Devlete Osmanlı Siyaseti(1300-1453)',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Dünya Gücü Osmanlı Devleti (1453-1600)',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Yeni Çağ Avrupa Tarihi',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Yakın Çağ Avrupa Tarihi',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Osmanlı Devletinde Arayış Yılları',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: '18. Yüzyılda Değişim ve Diplomasi',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'En Uzun Yüzyıl',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Osmanlı Kültür ve Medeniyeti',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: '20. Yüzyılda Osmanlı Devleti',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'I. Dünya Savaşı',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Mondros Ateşkesi, İşgaller ve Cemiyetler',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Kurtuluş Savaşına Hazırlık Dönemi',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'I. TBMM Dönemi',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Kurtuluş Savaşı ve Antlaşmalar',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'II. TBMM Dönemi ve Çok Partili Hayata Geçiş',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'Türk İnkılabı',
                videos: [],
                questions: [],
            },
            {
                id: '24',
                name: 'Atatürk İlkeleri',
                videos: [],
                questions: [],
            },
            {
                id: '25',
                name: 'Atatürk Dönemi Türk Dış Politikası',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '8',
        name: 'Coğrafya',
        topics: [
            {
                id: '1',
                name: 'Doğa ve İnsan',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Dünya’nın Şekli ve Hareketleri',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Coğrafi Konum',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Harita Bilgisi',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Atmosfer ve Sıcaklık',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'İklimler',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Basınç ve Rüzgarlar',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Nem, Yağış ve Buharlaşma',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'İç Kuvvetler / Dış Kuvvetler',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Su – Toprak ve Bitkiler',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Nüfus',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Göç',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Yerleşme',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Türkiye’nin Yer Şekilleri',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Ekonomik Faaliyetler',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Bölgeler',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Uluslararası Ulaşım Hatları',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Çevre ve Toplum',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Doğal Afetler',
                videos: [],
                questions: [],
            },
        ],
    },
];

