import { Link } from 'react-router-dom';
import {FaBook} from "react-icons/fa";

const Home = () => (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 px-4">
        <FaBook className="text-6xl mb-10 text-primary animate-fadeIn" />
        <h1 className="text-4xl md:text-6xl font-extrabold text-white mb-12 text-center animate-fadeIn">
            YKS Takip Uygulaması
        </h1>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
            <Link
                to="/tyt"
                className="btn-primary w-full md:w-auto text-center animate-fadeIn"
            >
                TYT
            </Link>
            <Link
                to="/ayt"
                className="btn-primary w-full md:w-auto text-center animate-fadeIn"
            >
                AYT
            </Link>
        </div>
    </div>
);

export default Home;
