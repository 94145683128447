interface IVideo {
    id: string;
    title: string;
    url: string;
}

interface IQuestion {
    id: string;
    title: string;
    url: string;
}

interface ITopic {
    id: string;
    name: string;
    videos: IVideo[];
    questions: IQuestion[];
}

interface ISubject {
    id: string;
    name: string;
    topics: ITopic[];
}

export const aytSubjects: ISubject[] = [
    {
        id: '1',
        name: 'Matematik',
        topics: [
            {
                id: '1',
                name: 'Temel Kavramlar',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Sayı Basamakları',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Bölme ve Bölünebilme',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'EBOB - EKOK',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Rasyonel Sayılar',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Basit Eşitsizlikler',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Mutlak Değer',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Üslü Sayılar',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Köklü Sayılar',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Çarpanlara Ayırma',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Oran Orantı',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Denklem Çözme',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Problemler',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Kümeler',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Kartezyen Çarpım',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Mantık',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Fonksiyonlar',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Polinomlar',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: '2. Dereceden Denklemler',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Permütasyon ve Kombinasyon',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Binom ve Olasılık',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'İstatistik',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'Karmaşık Sayılar',
                videos: [],
                questions: [],
            },
            {
                id: '24',
                name: '2. Dereceden Eşitsizlikler',
                videos: [],
                questions: [],
            },
            {
                id: '25',
                name: 'Parabol',
                videos: [],
                questions: [],
            },
            {
                id: '26',
                name: 'Trigonometri',
                videos: [],
                questions: [],
            },
            {
                id: '27',
                name: 'Logaritma',
                videos: [],
                questions: [],
            },
            {
                id: '28',
                name: 'Diziler',
                videos: [],
                questions: [],
            },
            {
                id: '29',
                name: 'Limit',
                videos: [],
                questions: [],
            },
            {
                id: '30',
                name: 'Türev',
                videos: [],
                questions: [],
            },
            {
                id: '31',
                name: 'İntegral',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '2',
        name: 'Geometri',
        topics: [
            {
                id: '1',
                name: 'Temel Kavramlar',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Doğruda Açılar',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Üçgende Açılar',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Özel Üçgenler',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Dik Üçgen',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'İkizkenar Üçgen',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Eşkenar Üçgen',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Açıortay',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Kenarortay',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Üçgende Alan',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Üçgende Benzerlik',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Açı Kenar Bağıntıları',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Çokgenler',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Özel Dörtgenler',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Dörtgenler',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Deltoid',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Paralelkenar',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Eşkenar Dörtgen',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Dikdörtgen',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Kare',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'İkizkenar',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'Yamuk',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'Çember ve Daire',
                videos: [],
                questions: [],
            },
            {
                id: '24',
                name: 'Analitik Geometri',
                videos: [],
                questions: [],
            },
            {
                id: '25',
                name: 'Noktanın Analitiği',
                videos: [],
                questions: [],
            },
            {
                id: '26',
                name: 'Doğrunun Analitiği',
                videos: [],
                questions: [],
            },
            {
                id: '27',
                name: 'Dönüşüm Geometrisi',
                videos: [],
                questions: [],
            },
            {
                id: '28',
                name: 'Katı Cisimler (Uzay Geometri)',
                videos: [],
                questions: [],
            },
            {
                id: '29',
                name: 'Dikdörtgenler Prizması',
                videos: [],
                questions: [],
            },
            {
                id: '30',
                name: 'Küp',
                videos: [],
                questions: [],
            },
            {
                id: '31',
                name: 'Silindir',
                videos: [],
                questions: [],
            },
            {
                id: '32',
                name: 'Piramit',
                videos: [],
                questions: [],
            },
            {
                id: '33',
                name: 'Koni',
                videos: [],
                questions: [],
            },
            {
                id: '34',
                name: 'Küre',
                videos: [],
                questions: [],
            },
            {
                id: '35',
                name: 'Çemberin Analitiği',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '3',
        name: 'Fizik',
        topics: [
            {
                id: '1',
                name: 'Vektörler',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Kuvvet, Tork ve Denge',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Kütle Merkezi',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Basit Makineler',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Hareket',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Newton’un Hareket Yasaları',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'İş, Güç ve Enerji II',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Atışlar',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'İtme ve Momentum',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Elektrik Alan ve Potansiyel',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Paralel Levhalar ve Sığa',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Manyetik Alan ve Manyetik Kuvvet',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'İndüksiyon, Alternatif Akım ve Transformatörler',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Çembersel Hareket',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Dönme, Yuvarlanma ve Açısal Momentum',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Kütle Çekim ve Kepler Yasaları',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Basit Harmonik Hareket',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Dalga Mekaniği ve Elektromanyetik Dalgalar',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Atom Modelleri',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Büyük Patlama ve Parçacık Fiziği',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Radyoaktivite',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'Özel Görelilik',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'Kara Cisim Işıması',
                videos: [],
                questions: [],
            },
            {
                id: '24',
                name: 'Fotoelektrik Olay ve Compton Olayı',
                videos: [],
                questions: [],
            },
            {
                id: '25',
                name: 'Modern Fiziğin Teknolojideki Uygulamaları',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '4',
        name: 'Kimya',
        topics: [
            {
                id: '1',
                name: 'Kimya Bilimi',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Atom ve Periyodik Sistem',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Kimyasal Türler Arası Etkileşimler',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Kimyasal Hesaplamalar',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Kimyanın Temel Kanunları',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Asit, Baz ve Tuz',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Maddenin Halleri',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Karışımlar',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Doğa ve Kimya',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Kimya Her Yerde',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Modern Atom Teorisi',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Gazlar',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Sıvı Çözeltiler',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Kimyasal Tepkimelerde Enerji',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Kimyasal Tepkimelerde Hız',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Kimyasal Tepkimelerde Denge',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Asit-Baz Dengesi',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Çözünürlük Dengesi',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Kimya ve Elektrik',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Organik Kimyaya Giriş',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Organik Kimya',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'Enerji Kaynakları ve Bilimsel Gelişmeler',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '5',
        name: 'Biyoloji',
        topics: [
            {
                id: '1',
                name: 'Sinir Sistemi',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Endokrin Sistem ve Hormonlar',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Duyu Organları',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Destek ve Hareket Sistemi',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Sindirim Sistemi',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Dolaşım ve Bağışıklık Sistemi',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Solunum Sistemi',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Üriner Sistem (Boşaltım Sistemi)',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Üreme Sistemi ve Embriyonik Gelişim',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Komünite Ekolojisi',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Popülasyon Ekolojisi',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Genden Proteine',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Nükleik Asitler',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Genetik Şifre ve Protein Sentezi',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Canlılarda Enerji Dönüşümleri',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Canlılık ve Enerji',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Fotosentez',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Kemosentez',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Hücresel Solunum',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Bitki Biyolojisi',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Canlılar ve Çevre',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '6',
        name: 'Edebiyat',
        topics: [
            {
                id: '1',
                name: 'Anlam Bilgisi',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Dil Bilgisi',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Güzel Sanatlar ve Edebiyat',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Metinlerin Sınıflandırılması',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Şiir Bilgisi',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Edebi Sanatlar',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Türk Edebiyatı Dönemleri',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'İslamiyet Öncesi Türk Edebiyatı ve Geçiş Dönemi',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Halk Edebiyatı',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Divan Edebiyatı',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Tanzimat Edebiyatı',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Servet-i Fünun Edebiyatı',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Fecr-i Ati Edebiyatı',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Milli Edebiyat',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Cumhuriyet Dönemi Edebiyatı',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Edebiyat Akımları',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Dünya Edebiyatı',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '7',
        name: 'Tarih',
        topics: [
            {
                id: '1',
                name: 'Tarih ve Zaman',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'İnsanlığın İlk Dönemleri',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Orta Çağ’da Dünya',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'İlk ve Orta Çağlarda Türk Dünyası',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'İslam Medeniyetinin Doğuşu',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Türklerin İslamiyet’i Kabulü ve İlk Türk İslam Devletleri',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Yerleşme ve Devletleşme Sürecinde Selçuklu Türkiyesi',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Beylikten Devlete Osmanlı Siyaseti',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Devletleşme Sürecinde Savaşçılar ve Askerler',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Beylikten Devlete Osmanlı Medeniyeti',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Dünya Gücü Osmanlı',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Sultan ve Osmanlı Merkez Teşkilatı',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Klasik Çağda Osmanlı Toplum Düzeni',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Değişen Dünya Dengeleri Karşısında Osmanlı Siyaseti',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Değişim Çağında Avrupa ve Osmanlı',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Uluslararası İlişkilerde Denge Stratejisi (1774-1914)',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Devrimler Çağında Değişen Devlet-Toplum İlişkileri',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Sermaye ve Emek',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'XIX. ve XX. Yüzyılda Değişen Gündelik Hayat',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'XX. Yüzyıl Başlarında Osmanlı Devleti ve Dünya',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Milli Mücadele',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'Atatürkçülük ve Türk İnkılabı',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'İki Savaş Arasındaki Dönemde Türkiye ve Dünya',
                videos: [],
                questions: [],
            },
            {
                id: '24',
                name: 'II. Dünya Savaşı Sürecinde Türkiye ve Dünya',
                videos: [],
                questions: [],
            },
            {
                id: '25',
                name: 'II. Dünya Savaşı Sonrasında Türkiye ve Dünya',
                videos: [],
                questions: [],
            },
            {
                id: '26',
                name: 'Toplumsal Devrim Çağında Dünya ve Türkiye',
                videos: [],
                questions: [],
            },
            {
                id: '27',
                name: 'XXI. Yüzyılın Eşiğinde Türkiye ve Dünya',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '8',
        name: 'Coğrafya',
        topics: [
            {
                id: '1',
                name: 'Ekosistem',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Biyoçeşitlilik',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Biyomlar',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Ekosistemin Unsurları',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Enerji Akışı ve Madde Döngüsü',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Ekstrem Doğa Olayları',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Küresel İklim Değişimi',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'Nüfus Politikaları',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'Türkiye’de Nüfus ve Yerleşme',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'Ekonomik Faaliyetler ve Doğal Kaynaklar',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: 'Göç ve Şehirleşme',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: 'Türkiye Ekonomisi',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: 'Türkiye’nin Ekonomi Politikaları',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Türkiye Ekonomisinin Sektörel Dağılımı',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Türkiye’de Tarım',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Türkiye’de Hayvancılık',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Türkiye’de Madenler ve Enerji Kaynakları',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Türkiye’de Sanayi',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Türkiye’de Ulaşım',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Türkiye’de Ticaret ve Turizm',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Geçmişten Geleceğe Şehir ve Ekonomi',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'Türkiye’nin İşlevsel Bölgeleri ve Kalkınma Projeleri',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'Hizmet Sektörünün Ekonomideki Yeri',
                videos: [],
                questions: [],
            },
            {
                id: '24',
                name: 'Küresel Ticaret',
                videos: [],
                questions: [],
            },
            {
                id: '25',
                name: 'Bölgeler ve Ülkeler',
                videos: [],
                questions: [],
            },
            {
                id: '26',
                name: 'İlk Uygarlıklar',
                videos: [],
                questions: [],
            },
            {
                id: '27',
                name: 'Kültür Bölgeleri ve Türk Kültürü',
                videos: [],
                questions: [],
            },
            {
                id: '28',
                name: 'Sanayileşme Süreci: Almanya',
                videos: [],
                questions: [],
            },
            {
                id: '29',
                name: 'Tarım ve Ekonomi İlişkisi Fransa – Somali',
                videos: [],
                questions: [],
            },
            {
                id: '30',
                name: 'Ülkeler Arası Etkileşim',
                videos: [],
                questions: [],
            },
            {
                id: '31',
                name: 'Jeopolitik Konum',
                videos: [],
                questions: [],
            },
            {
                id: '32',
                name: 'Çatışma Bölgeleri',
                videos: [],
                questions: [],
            },
            {
                id: '33',
                name: 'Küresel ve Bölgesel Örgütler',
                videos: [],
                questions: [],
            },
            {
                id: '34',
                name: 'Çevre ve Toplum',
                videos: [],
                questions: [],
            },
            {
                id: '35',
                name: 'Çevre Sorunları ve Türleri',
                videos: [],
                questions: [],
            },
            {
                id: '36',
                name: 'Madenler ve Enerji Kaynaklarının Çevreye Etkisi',
                videos: [],
                questions: [],
            },
            {
                id: '37',
                name: 'Doğal Kaynakların Sürdürülebilir Kullanımı',
                videos: [],
                questions: [],
            },
            {
                id: '38',
                name: 'Ekolojik Ayak İzi',
                videos: [],
                questions: [],
            },
            {
                id: '39',
                name: 'Doğal Çevrenin Sınırlılığı',
                videos: [],
                questions: [],
            },
            {
                id: '40',
                name: 'Çevre Politikaları',
                videos: [],
                questions: [],
            },
            {
                id: '41',
                name: 'Çevresel Örgütler',
                videos: [],
                questions: [],
            },
            {
                id: '42',
                name: 'Çevre Anlaşmaları',
                videos: [],
                questions: [],
            },
            {
                id: '43',
                name: 'Doğal Afetler',
                videos: [],
                questions: [],
            },
        ],
    },
    {
        id: '9',
        name: 'Felsefe',
        topics: [
            {
                id: '1',
                name: 'Bilgi Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '2',
                name: 'Varlık Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '3',
                name: 'Ahlak Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '4',
                name: 'Sanat Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '5',
                name: 'Din Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '6',
                name: 'Siyaset Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '7',
                name: 'Bilim Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '8',
                name: 'İlk Çağ Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '9',
                name: 'MÖ 6. Yüzyıl – MS 2. Yüzyıl Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '10',
                name: 'MS 2. Yüzyıl – MS 15. Yüzyıl Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '11',
                name: '15. Yüzyıl – 17. Yüzyıl Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '12',
                name: '18. Yüzyıl – 19. Yüzyıl Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '13',
                name: '20. Yüzyıl Felsefesi',
                videos: [],
                questions: [],
            },
            {
                id: '14',
                name: 'Mantığa Giriş',
                videos: [],
                questions: [],
            },
            {
                id: '15',
                name: 'Klasik Mantık',
                videos: [],
                questions: [],
            },
            {
                id: '16',
                name: 'Mantık ve Dil',
                videos: [],
                questions: [],
            },
            {
                id: '17',
                name: 'Sembolik Mantık',
                videos: [],
                questions: [],
            },
            {
                id: '18',
                name: 'Psikolojinin Temel Süreçleri',
                videos: [],
                questions: [],
            },
            {
                id: '19',
                name: 'Öğrenme Bellek Düşünme',
                videos: [],
                questions: [],
            },
            {
                id: '20',
                name: 'Ruh Sağlığının Temelleri',
                videos: [],
                questions: [],
            },
            {
                id: '21',
                name: 'Birey ve Toplum',
                videos: [],
                questions: [],
            },
            {
                id: '22',
                name: 'Toplumsal Yapı',
                videos: [],
                questions: [],
            },
            {
                id: '23',
                name: 'Toplumsal Değişme ve Gelişme',
                videos: [],
                questions: [],
            },
            {
                id: '24',
                name: 'Toplum ve Kültür',
                videos: [],
                questions: [],
            },
            {
                id: '25',
                name: 'Toplumsal Kurumlar',
                videos: [],
                questions: [],
            },
        ],
    },
];

