import { FaVideo, FaQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

interface TopicCardProps {
    subjectId: string;
    topicId: string;
    title: string;
    videos: { id: string; title: string; url: string }[];
    questions: { id: string; title: string; url: string }[];
}

const TopicCard: React.FC<TopicCardProps> = ({ subjectId, topicId, title, videos, questions }) => {
    const MAX_ITEMS_TO_SHOW = 2;
    const MAX_TITLE_LENGTH = 40;

    // Local storage'dan veriyi almak ve güncellemek için state
    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    useEffect(() => {
        // Sayfa yüklendiğinde localStorage'dan konu tamamlanma durumunu kontrol et
        const completedTopics = JSON.parse(localStorage.getItem('completedTopics') || '{}');
        setIsCompleted(completedTopics[`${subjectId}-${topicId}`] || false);
    }, [subjectId, topicId]);

    const handleComplete = () => {
        // Tamamlanma durumunu değiştir ve localStorage'a kaydet
        const updatedStatus = !isCompleted;
        setIsCompleted(updatedStatus);

        // LocalStorage'da mevcut tamamlanan konular
        const completedTopics = JSON.parse(localStorage.getItem('completedTopics') || '{}');
        completedTopics[`${subjectId}-${topicId}`] = updatedStatus; // Mevcut durumu güncelle

        // LocalStorage'a tekrar yaz
        localStorage.setItem('completedTopics', JSON.stringify(completedTopics));
    };

    const truncateTitle = (title: string, maxLength: number) => {
        return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
    };

    return (
        <div className={`bg-gray-700/75 backdrop-blur-sm text-white rounded-xl shadow-xl p-6 mb-6 transform hover:scale-105 transition-all duration-300 ease-in-out border border-gray-700 ${isCompleted ? 'border-green-500' : ''}`}>
            <Link to={`subject/${subjectId}/${topicId}`} className="text-xl font-semibold hover:text-primary transition-colors">
                <h3 className={`text-3xl font-bold ${isCompleted ? 'text-green-500' : 'text-primary'} mb-6 hover:underline`}>{title}</h3>
            </Link>
            <div className="grid grid-rows- md:grid-cols-2 gap-8">
                {/* Videolar Bölümü */}
                <div>
                    <h4 className="text-lg font-medium text-gray-400 flex items-center mb-2">
                        <FaVideo className="mr-2 text-primary" /> Konu Anlatım Videoları
                    </h4>
                    {videos.length > 0 ? (
                        <ul className="list-disc ml-5 space-y-2">
                            {videos.slice(0, MAX_ITEMS_TO_SHOW).map((video) => (
                                <li key={video.id}>
                                    <a
                                        href={video.url}
                                        className="text-primary hover:text-primary-dark transition-colors"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {truncateTitle(video.title, MAX_TITLE_LENGTH)}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-gray-500">Video bulunmamaktadır.</p>
                    )}
                </div>

                {/* Sorular ve Materyaller Bölümü */}
                <div>
                    <h4 className="text-lg font-medium text-gray-400 flex items-center mb-2">
                        <FaQuestionCircle className="mr-2 text-primary" /> Soru ve Materyaller
                    </h4>
                    {questions.length > 0 ? (
                        <ul className="list-disc ml-5 space-y-2">
                            {questions.slice(0, MAX_ITEMS_TO_SHOW).map((question) => (
                                <li key={question.id}>
                                    <a
                                        href={question.url}
                                        className="text-primary hover:text-primary-dark transition-colors"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {truncateTitle(question.title, MAX_TITLE_LENGTH)}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-gray-500">Soru ve materyal bulunmamaktadır.</p>
                    )}
                </div>
            </div>

            {/* Tamamladım Butonu */}
            <button
                onClick={handleComplete}
                className={`mt-4 px-4 py-2 rounded-full text-white font-semibold transition-colors ${isCompleted ? 'bg-green-500 hover:bg-green-600' : 'bg-primary hover:bg-primary-dark'}`}
            >
                {isCompleted ? 'Tamamlandı' : 'Tamamladım'}
            </button>
        </div>
    );
};

export default TopicCard;
