import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { tytSubjects } from '../data/tytSubjects';
import { FaVideo, FaQuestionCircle, FaCheckCircle } from 'react-icons/fa'; // İkonlar için ekleme

const TopicDetail = () => {
    const { subjectId, topicId } = useParams();
    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    // İlgili dersi ve konuyu buluyoruz
    const subject = tytSubjects.find((subject) => subject.id === subjectId);
    const topic = subject?.topics.find((topic) => topic.id === topicId);

    useEffect(() => {
        // LocalStorage'dan konu tamamlanma durumunu kontrol et
        const completedTopics = JSON.parse(localStorage.getItem('completedTopics') || '{}');
        setIsCompleted(completedTopics[`${subjectId}-${topicId}`] || false);
    }, [subjectId, topicId]);

    if (!subject || !topic) {
        return <div>Konular Bulunamadı</div>;
    }

    return (
        <div className="p-8 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 min-h-screen text-white">
            <div className="text-center mb-8">
                <h2 className={`text-4xl font-extrabold ${isCompleted ? 'text-green-500' : 'text-primary'} mb-4`}>
                    {subject.name} - {topic.name}
                </h2>
                {isCompleted && (
                    <div className="text-green-500 flex justify-center items-center space-x-2">
                        <FaCheckCircle className="text-green-500" />
                        <span>Bu konuyu tamamladınız!</span>
                    </div>
                )}
            </div>

            <div className="grid grid-cols-1 gap-8">
                {/* Video Listesi */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md backdrop-blur-md border border-gray-700">
                    <h3 className="text-3xl font-semibold text-primary flex items-center mb-4">
                        <FaVideo className="mr-3 text-primary" /> Konu Anlatım Videoları
                    </h3>
                    {topic.videos.length > 0 ? (
                        <ul className="list-disc ml-5 space-y-3">
                            {topic.videos.map((video) => (
                                <li key={video.id}>
                                    <a
                                        href={video.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary hover:text-primary-dark transition-colors"
                                    >
                                        {video.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-gray-400">Bu konu için video bulunmamaktadır.</p>
                    )}
                </div>

                {/* Soru Listesi */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md backdrop-blur-md border border-gray-700">
                    <h3 className="text-3xl font-semibold text-primary flex items-center mb-4">
                        <FaQuestionCircle className="mr-3 text-primary" /> Soru Çözüm Materyalleri
                    </h3>
                    {topic.questions.length > 0 ? (
                        <ul className="list-disc ml-5 space-y-3">
                            {topic.questions.map((question) => (
                                <li key={question.id}>
                                    <a
                                        href={question.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary hover:text-primary-dark transition-colors"
                                    >
                                        {question.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-gray-400">Bu konu için soru bulunmamaktadır.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopicDetail;
