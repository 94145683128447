import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import TYTPage from './pages/TYTPage';
import AYTPage from './pages/AYTPage';
import TopicDetail from "./pages/TopicDetail"; // if needed

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tyt" element={<TYTPage />} />
          <Route path="/ayt" element={<AYTPage />} />
            <Route path="/tyt/subject/:subjectId/:topicId" element={<TopicDetail />} />
            <Route path="/ayt/subject/:subjectId/:topicId" element={<TopicDetail />} />
        </Routes>
      </Router>
  );
}

export default App;
